import { Routes } from '@angular/router';
import { redirectGuard } from './redirect.guard';
import { CompanyComponent } from './company/company.component';
import { environment } from '../environments/environment';

export const routes: Routes = [
  {
    path: '',
    canActivate: [redirectGuard],
    data: {
      externalUrl: environment.schedwiseHostname,
    },
    component: CompanyComponent,
  },
  {
    path: 'health',
    loadComponent: () =>
      import('./health/health.component').then((mod) => mod.HealthComponent),
  },
  {
    path: ':locationUniqueName',
    loadComponent: () =>
      import('./company/company.component').then((mod) => mod.CompanyComponent),
  },
  { path: '**', redirectTo: '' },
];
