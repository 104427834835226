import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { TranslocoService } from '@jsverse/transloco';
import { getBrowserLang } from '@jsverse/transloco';

import { CUSTOM_MAT_ICONS } from './custom-mat-icons.config';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'schedwise-company-info';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translocoService: TranslocoService
  ) {
    const browsLang = getBrowserLang() || 'en';
    const activeLang = translocoService
      .getAvailableLangs()
      .map((l) => l.toString())
      .includes(browsLang)
      ? browsLang
      : 'en';
    translocoService.setActiveLang(activeLang);
  }

  ngOnInit(): void {
    this.addCustomMatIcons();
  }

  /**
   * Adds custom mat icons.
   */
  private addCustomMatIcons(): void {
    CUSTOM_MAT_ICONS.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
