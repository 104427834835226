export const CUSTOM_MAT_ICONS = [
  {
    name: 'facebook',
    url: 'assets/icons/facebook-icon.svg',
  },
  {
    name: 'instagram',
    url: 'assets/icons/instagram-icon.svg',
  },
  {
    name: 'linkedIn',
    url: 'assets/icons/linkedIn-icon.svg',
  },
  {
    name: 'twitter',
    url: 'assets/icons/twitter-icon.svg',
  },
];
